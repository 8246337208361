.react-datepicker-popper {
  margin-left: 30px;
}



.card-header {
  color:#eee !important;
}
.card-header .form-control{
  height: 100% !important;
}

.border-primary.kfc{
  border-color: rgb(32,63,95) !important;
}

.modal-sm {
  width:75% !important;
  left:10%
}

#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 9999;
  cursor: pointer;

}

.react-datepicker-wrapper{
  display: block !important;
}

.bg-secondary {
  background-color: #e9ecef!important;
}
.input-group-text{
  border-radius: 0 !important;
}
.react-datepicker-popper{
  margin-left: 0 !important;
}
.bills .react-datepicker-wrapper{
  
  width: 1%;
  min-width: 0;
  flex: 1 1 auto;

}
.bills .react-datepicker__input-container{
  height: 100% !important;
}

.text-secondary{
  /* color:#999da0 !important; */
}
.nav-item .nav-link:not(.active):not(.link){
  color: #f8f9fa!important;
}
.dropdown-item.active .nav-link.link{
  color: #f8f9fa!important;
}

.tiles span{
  vertical-align: middle;
}
.sb-avatar__text span{
  font-size: 1.5rem !important;
}

.cust-loader{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row; /* OPTIONAL IF ONLY ONE ELEMENT */
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
.bg-dark-cust{
  background: white !important;
}

.bg-dark-cust1{
  background: #43cea2;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #185a9d, #43cea2);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #185a9d, #43cea2); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  height:100vh
}

.bg-dark{
  background: #1a344e;  /* fallback for old browsers */
  background: -webkit-linear-gradient(#3f7abb, #1a344e);
 
  background:linear-gradient(#3f7abb, #1a344e) !important
}

.bg-dark2{
  background: #606c88;  /* fallback for old browsers */
background: -webkit-linear-gradient(to bottom, #3f4c6b, #606c88);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to bottom, #3f4c6b, #606c88); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.fa-2x{

  font-size: 1.2rem !important;
}

.card{
  /* border-color: #1e3c72 !important; */
}

.border-dark, .accordion-item{
   border-color: #3f7abb !important; 
}

.btn-primary{
  border-color: #3f7abb !important;
  background-image:linear-gradient(#3f7abb, #1a344e) !important
}
.btn-success{

  background-image: linear-gradient(#91BE5D,#00940A)  !important

}

.btn-danger{
  
  background-image:  linear-gradient(#be5d5d,#940000);
}


.fa {
  font-size: .75rem;
}
.fa-4x{
  font-size: 4em !important;
}

.card-header .btn{
  line-height: .75;
}


.profile-img-card {
 
  margin: 0 auto 10px;
  display: block;
 
}

.card-login {
  width:18rem;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}
.searchBar.col-sm{
  width:100%;
  
}

.nav-pills .nav-link.active{
  background-color: #fff!important;
  color: #212529!important;
}

.link{
  text-decoration: none;
  color: #212529 !important;
}
.left-drop .dropdown-menu{
  right:0
}
.navbar-brand .nav-link{
  padding: 0 !important;
}

.navbar-collapse.show .navbar-nav, .dropdown-menu{
  text-align: center;
}
/* class for button spacing  d-flex justify-content-end gap-2 */
/* class for block button d-grid d-md-flex gap-2 justify-content-md-end */
.collapsing {
  -webkit-transition: none;
  transition: none !important;
  display: none;
}
.card-header .nav-link{
  padding:2px;
  padding-right:4px ;
  padding-left: 4px;
}

.fs-l7{
  font-size:.7rem
}
.border-black{
  border-color: rgba(53, 50, 50, 0.541) !important;
}
.bg-quantity{
  background-color: #d9edfe !important;
}

#state-down.dropdown-toggle::after {
  display:none;
}
.text-warning{
  color:#efb404 !important
}